<template>
    <!-- 会员充值设置 -->
    <div class="rb-view">
        <el-form :model="form_data" :ref="form_name" :rules="form_rules" size="mini" label-width="280px" style="max-width: 800px" inline>
            <el-row>
                <el-col>
                    <el-form-item label="支付超时设置(秒)" prop="pay_timeout_seconds" required>
                        <el-input-number v-model="form_data.pay_timeout_seconds" :min="30" :max="24*60*60"></el-input-number>
                        <div class="rb-input-tip">提交充值订单多久没支付取消订单,最低设置5分钟,最多24小时</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="工作日会员每月赠送的优惠券ID:" prop="weekday_coupon_id">
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('weekday_coupon_id')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.weekday_coupon_id && form_data.weekday_coupon_id>0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.weekday_coupon_id}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.weekday_coupon_id" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('weekday_coupon_id')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.weekday_coupon_id && weekday_coupon && weekday_coupon.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{weekday_coupon.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{weekday_coupon.coupon_name}}</div>
                                    <div>{{weekday_coupon.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="工作日会员每月赠送的指定优惠券数量:" prop="weekday_coupon_count">
                        <el-input-number v-model="form_data.weekday_coupon_count" :min="0" :max="3"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="巅峰会员每月赠送的优惠券ID:" prop="everyday_coupon_id">
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('everyday_coupon_id')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.everyday_coupon_id && form_data.everyday_coupon_id>0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.everyday_coupon_id}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.everyday_coupon_id" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('everyday_coupon_id')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.everyday_coupon_id && everyday_coupon && everyday_coupon.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{everyday_coupon.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{everyday_coupon.coupon_name}}</div>
                                    <div>{{everyday_coupon.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="巅峰会员每月赠送的指定优惠券数量:" prop="everyday_coupon_count">
                        <el-input-number v-model="form_data.everyday_coupon_count" :min="0" :max="3"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="巅峰会员每月赠送的优惠券ID_2:" prop="everyday_coupon_id_2">
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('everyday_coupon_id_2')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.everyday_coupon_id_2 && form_data.everyday_coupon_id_2 > 0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.everyday_coupon_id_2}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.everyday_coupon_id_2" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('everyday_coupon_id_2')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.everyday_coupon_id_2 && everyday_coupon_2 && everyday_coupon_2.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{everyday_coupon_2.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{everyday_coupon_2.coupon_name}}</div>
                                    <div>{{everyday_coupon_2.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="巅峰会员每月赠送的指定优惠券数量_2:" prop="everyday_coupon_count_2">
                        <el-input-number v-model="form_data.everyday_coupon_count_2" :min="0" :max="3"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

            <div style="display:flex;flex-direction:row;justify-content:flex-end;width:100%;">
                <div style="float:right;">
                    <el-button @click="handleSubmitTap(form_name)" size="mini" type="primary">保存</el-button>
                </div>
            </div>
        </el-form>


        <el-dialog v-model="dialog_coupon_select_list_visible" :title="dialog_coupon_select_list_title" @close="onTellSelectCouponDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <coupon-select-list :field="dialog_coupon_select_field" mode="single" @onTellDialogClose="onTellSelectCouponDialogClose" @onCouponSelectConfirm="onCouponSelectConfirm"></coupon-select-list>
        </el-dialog>
    </div>
</template>

<script>
    import { getRechargeSetting, setRechargeSetting} from "@/api/hado/setting";
    import {getDetail as getCouponDetail} from "@/api/hado/coupon";
    import CouponSelectList from '@/views/b/market/coupon-select-list'
    export default {
        name: "recharge-setting",
        components:{CouponSelectList},
        data(){
            return{
                form_name:'order_play_setting_form',
                form_data:{},
                form_rules:{
                    pay_timeout_seconds:[{required:true,message:'请输入支付超时设置',trigger:'change'}],
                },
                weekday_coupon:undefined,
                everyday_coupon:undefined,
                everyday_coupon_2:undefined,

                dialog_coupon_select_list_visible:false,
                dialog_coupon_select_list_title:'请选择要派发的优惠券',
                dialog_coupon_select_field:'',

            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                getRechargeSetting().then(resp=>{
                    console.log('resp:',resp)
                    this.form_data = resp.data

                    //这里如果设置了优惠券ID的 再获取一下优惠券ID
                    if(resp.data.weekday_coupon_id&&resp.data.weekday_coupon_id>0) {
                        getCouponDetail(resp.data.weekday_coupon_id).then(resp2=>{
                            this.weekday_coupon = resp2.data;
                        })
                    }
                    if(resp.data.everyday_coupon_id&&resp.data.everyday_coupon_id>0) {
                        getCouponDetail(resp.data.everyday_coupon_id).then(resp2=>{
                            this.everyday_coupon = resp2.data;
                        })
                    }
                    if(resp.data.everyday_coupon_id_2&&resp.data.everyday_coupon_id_2>0) {
                        getCouponDetail(resp.data.everyday_coupon_id_2).then(resp2=>{
                            this.everyday_coupon_2 = resp2.data;
                        })
                    }
                })
            },
            reloadPage(){
                this.initPage()
            },
            handleSubmitTap(form_name) {
                this.$refs[form_name].validate((valid)=>{
                    if(valid){
                        setRechargeSetting(this.form_data).then(()=>{
                            this.reloadPage()
                        })
                    }
                })
            },
            handleOnSelectCoupon(field_name){
                console.log('field_name:',field_name)
                this.dialog_coupon_select_field = field_name
                this.dialog_coupon_select_list_visible=true
            },
            handleOnClearCouponId(field_name){
                console.log('field_name:',field_name)
            },
            onTellSelectCouponDialogClose(){
                this.dialog_coupon_select_field = ''
                this.dialog_coupon_select_list_visible=false
            },
            //优惠券选择通知事件
            onCouponSelectConfirm(e){
                console.log('onCouponSelectConfirm:',e)
                //这里先做修改编辑处理 然后再关闭弹窗
                getCouponDetail(e.coupon_id).then(resp=>{
                    console.log('优惠券再次查询返回:',resp)
                    let coupon = resp.data;
                    // this.form_data.group_success_send_coupon_id = coupon.id;
                    this.form_data[e.field] = coupon.id
                    // this.send_coupon = coupon;
                    if(e.field === 'weekday_coupon_id'){
                        this.weekday_coupon = coupon
                    }else if(e.field === 'everyday_coupon_id'){
                        this.everyday_coupon = coupon
                    }else if(e.field === 'everyday_coupon_id_2'){
                        this.everyday_coupon_2 = coupon
                    }
                    this.onTellSelectCouponDialogClose()
                })
            },
        }
    }
</script>

<style scoped>
    .rb-input-tip{
        color: #969799;
        line-height: 14px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>
