import request from "@/utils/request";

export const getOrderPlaySetting = ()=>{
    return request({
        url:'/api/hado/setting/get-order-play-setting',
        method:'get',
    })
}

export const setOrderPlaySetting = (data)=>{
    return request({
        url:'/api/hado/setting/set-order-play-setting',
        method:'post',
        data:data
    })
}

export const getRechargeSetting = ()=>{
    return request({
        url:'/api/hado/setting/get-recharge-setting',
        method:'get',
    })
}

export const setRechargeSetting = (data)=>{
    return request({
        url:'/api/hado/setting/set-recharge-setting',
        method:'post',
        data:data
    })
}

export const getMatchSeasonSetting = ()=>{
    return request({
        url:'/api/hado/setting/get-match-season-setting',
        method:'get',
    })
}

export const setMatchSeasonSetting = (data)=>{
    return request({
        url:'/api/hado/setting/set-match-season-setting',
        method:'post',
        data:data
    })
}
export const getWeAppSetting = ()=>{
    return request({
        url:'/api/hado/setting/get-we-app-setting',
        method:'get',
    })
}

export const setWeAppSetting = (data)=>{
    return request({
        url:'/api/hado/setting/set-we-app-setting',
        method:'post',
        data:data
    })
}
